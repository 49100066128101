export const footer = [
  {
    "name": "Home",
    "url": "",
    "dropdownItems": [
      {
        "name": "About",
        "url": "/about"
      },
      {
        "name": "Contact",
        "url": "/contact"
      },
      // {
      //   "name": "Features",
      //   "url": "/test"
      // }
    ]
  },
  // {
  //   "name": "Services",
  //   "url": "",
  //   "dropdownItems": [
  //     {
  //       "name": "Features",
  //       "url": "/test"
  //     },
  //     {
  //       "name": "Features",
  //       "url": "/test"
  //     },
  //     {
  //       "name": "Features",
  //       "url": "/test"
  //     }
  //   ]
  // },
  // {
  //   "name": "Company",
  //   "url": "",
  //   "dropdownItems": [
  //     {
  //       "name": "Features",
  //       "url": "/test"
  //     },
  //     {
  //       "name": "Features",
  //       "url": "/test"
  //     },
  //     {
  //       "name": "Features",
  //       "url": "/test"
  //     }
  //   ]
  // }
]
